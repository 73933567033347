import React, { useState } from "react";
import "./promoForm.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PromoForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [agree, setAgree] = useState("");
  const [error, setError] = useState("");
  const [succ, setScc] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const list = {
      firstName,
      lastName,
      phone,
      email,
      agree,
    };

    setLoading(true); // Set loading to true before the request

    try {
      const response = await axios.post("/mail", list);
      if (response.data.error) {
        setError(response.data.error);
      } else {
        // Clear form fields if submission is successful
        setFirstName("");
        setLastname("");
        setPhone("");
        setEmail("");
        setAgree(false);
        setError(""); // Clear any previous error messages
        setScc("Thank you, you have successfully subscribed to our newsletter");
        navigate("/");
      }
    } catch (error) {
      setError(
        "An error occurred while submitting the form. Please try again."
      );
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after the request is completed
    }
  };

  return (
    <div className="form_wrap">
      <div className="form-container">
        <div className="form-container_head">
          <h2>Customer Information Form</h2>
          <p>Help us get to know you better</p>
        </div>
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="form_input">
            <label htmlFor="firstName">First Name</label>
            <input
              className="input"
              type="text"
              value={firstName}
              placeholder="John"
              required
              autoComplete="on"
              onChange={(e) => setFirstName(e.target.value)}
            />

            <label htmlFor="lastName">Last Name</label>
            <input
              className="input"
              type="text"
              value={lastName}
              placeholder="Smith"
              required
              autoComplete="on"
              onChange={(e) => setLastname(e.target.value)}
            />

            <label htmlFor="email">Email</label>
            <input
              className="input"
              type="email"
              value={email}
              placeholder="johnsmith@mail.com"
              required
              autoComplete="on"
              onChange={(e) => setEmail(e.target.value)}
            />

            <label htmlFor="phone">Mobile</label>
            <input
              className="input"
              type="tel"
              placeholder="+9715012345678"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              autoComplete="on"
            />
          </div>
          <div className="check">
            <input
              type="checkbox"
              checked={agree === "agree"}
              onChange={(e) => setAgree(e.target.checked ? "agree" : "")}
              required
              autoComplete="on"
            />
            <small className="check_small">
              I agree to receive new promotions and <br /> newsletters from
              Rouge.
            </small>
          </div>

          <button type="submit">{loading ? "Loading..." : "Submit"}</button>
          <br />
          {succ && <small style={{ color: "green" }}>{succ}</small>}
          {error && <small style={{ color: "red" }}>{error}</small>}
        </form>
      </div>
    </div>
  );
};

export default PromoForm;
