import React from "react";
import "./whatapp.css";

const Whatapp = () => {
  return (
    <div>
      <a href="https://wa.me/971509010427" target="_blank" rel="noreferrer">
        <div className="whatapp-icon">
          <span className="what-text">Need Help? Chat with us</span>{" "}
          <img src={require("../../img/WhatsApp-Logo.wine.png")} alt="" />
        </div>
      </a>
    </div>
  );
};

export default Whatapp;
