import React from "react";
import "./Contact.scss";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Form from "../../components/form/Form";

const Contact = () => {
  return (
    <div className="max-width">
      <div className="contact">
        <div className="contact_info_wrap">
          <div className="con_data">
            <h1>Let’s contact</h1>
            <p>
              Have a question or need assistance? Send us a message, and we'll
              get back to you as soon as possible.
            </p>
          </div>
          <div className="contact_info">
            <div className="con_icon">
              <LocalPhoneIcon />
            </div>
            <div className="con_info">
              {" "}
              <p>+971 4 8832236</p>
            </div>
          </div>
          <div className="contact_info">
            <div className="con_icon">
              <EmailIcon />
            </div>
            <div className="con_info">
              {" "}
              <p>info@notionsgroup.com</p>
            </div>
          </div>
          <div className="contact_info">
            <div className="con_icon">
              <LocationOnIcon />
            </div>
            <div className="con_info">
              {" "}
              <p>
                Notions Group Dubai Investment Park 2 P.O. Box 37782 Dubai, UAE
              </p>
            </div>
          </div>
        </div>
        <div className="contact_form">
          <Form/>
        </div>
      </div>
    </div>
  );
};

export default Contact;
