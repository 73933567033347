import { Link } from "react-router-dom";
import backImg from "../../img/hero.jpg";
import "./category.scss";
import HomeIcon from "@mui/icons-material/Home";
import Pkg from "../FeaturedProducts/Pkg";

const GiftBoxes = () => {
  return (
    <div className="chocolate">
      <div
        className="pro_head"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
          width: "100%",
        }}
      >
        <h1>Boxes</h1>
      </div>
      <div className="max-width">
        <div className="home_nav">
          <Link to={"/"}>
            <span>
              <HomeIcon />
            </span>
          </Link>
          <span>|</span>
          <span> Boxes</span>
        </div>
        <div className="product_list">
          <Pkg pkg="Boxes" />
        </div>
      </div>
    </div>
  );
};

export default GiftBoxes;
