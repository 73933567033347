import React from 'react'
import backImg from "../../img/hero.jpg";
import FeaturedProducts from '../FeaturedProducts/FeaturedProducts';
import "./category.scss";
import { Link } from 'react-router-dom';
import HomeIcon from "@mui/icons-material/Home";

const Chocolates = () => {
  return (
    <div className="chocolate">
      <div
        className="pro_head"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
          width: "100%",
        }}
      >
        <h1>Chocolates</h1>
      </div>
      <div className="max-width">
        <div className="home_nav">
          <Link to={"/"}>
            <span>
              <HomeIcon />
            </span>
          </Link>
          <span>|</span>
          <span>Chocolates</span>
        </div>
        <div
          className="product_list"
          style={{ height: "30dvh", padding: "40px" }}
        >
          {/* <FeaturedProducts type="featured" /> */}
          <p>
            "Get ready for something extraordinary ! <br />{" "}
            <em style={{color:"red"}}>Stay tuned for an amazing surprise !"</em>
          </p>
          <br />
        </div>
      </div>
    </div>
  );
}

export default Chocolates
