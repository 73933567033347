import React from "react";
import "./Style.scss";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Link } from "react-router-dom";

const Failed = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="failed">
      <div className="card_wrap">
        <div className="logo">
          
        </div>
        <h1>
          Sorry <br /> payment failed!
        </h1>
        <div className="btn">
          <Link to="/products/1" onClick={scrollToTop}>
            <button>Continue Shopping</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Failed;
