import React from "react";
import backImg from "../../img/hero.jpg";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import "./category.scss";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
// import DataList from './data.js'

//Image list

import new_year from "../../img/season_img/new_year.webp";
import ch_newyear from "../../img/season_img/ch_newyear.webp";
import valentines from "../../img/season_img/valentines.webp";
import ramadan from "../../img/season_img/ramadan.webp";
import mothersday from "../../img/season_img/mothersday.webp";
import easter from "../../img/season_img/easter.webp";
import eid_alfitr from "../../img/season_img/eid_alfitr.jpg";
import eid_adha from "../../img/season_img/eid-adha.webp";
import hajj from "../../img/season_img/hajj.jpg";
import halloween from "../../img/season_img/halloween.jpg";
import back_to_school from "../../img/season_img/Backtoschool.jpg";
import teachers_day from "../../img/season_img/teachers_day.jpg";
import uae from "../../img/season_img/uae.jpg";
import diwali from "../../img/season_img/diwali.jpg";
import christmas from "../../img/season_img/christmas.jpg";
import womensday from "../../img/season_img/womensday.jpg";
import Fathers_day from "../../img/season_img/Fathers_day.jpg";

import Emirate_Day from "../../img/season_img/uaewomensday.jpg";


const DataList = [
  {
    heading: "New Year",
    img: `${new_year}`,
    des: "Ring in the New Year with our festive collection. Each chocolate is a celebration, perfect for welcoming a year full of joy and new beginnings.",
    // btn: "/new-year",
    index: "1",
  },
  {
    heading: "Chinese new year",
    img: `${ch_newyear}`,
    des: "Welcome the Chinese New Year with our special collection. Each chocolate is a symbol of prosperity and good fortune, perfect for celebrating the start of a new lunar year.",
    // btn: "/chinese-new-year",
    index: "2",
  },
  {
    heading: "Valentine’s Day",
    img: `${valentines}`,
    des: "Fall in love with our exquisite Valentine's Day collection. Each chocolate is a sweet gesture, perfect for celebrating love and joy on this special day.",
    // btn: "/valentine",
    index: "3",
  },
  {
    heading: "Ramadan",
    img: `${ramadan}`,
    des: "Embrace the spirit of Ramadan with our exclusive chocolate collection. Each piece is a colorful delight, symbolizing love, joy, and unity during this sacred time.",
    btn: "/ramadan",
    index: "4",
  },
  {
    heading: "Mothers’s Day",
    img: `${mothersday}`,
    des: "Show Mom your love with our Mother's Day collection. Each chocolate is a heartfelt tribute, perfect for celebrating the amazing mothers in our lives.",
    // btn: "/mothers-Day",
    index: "4",
  },
  {
    heading: "Easter",
    img: `${easter}`,
    des: "Hop into Easter with our fun-filled collection. Each chocolate is a delicious treat, perfect for adding joy to your Easter celebrations.",
    // btn: "/easter",
    index: "5",
  },
  {
    heading: "Eid Al-Fitr",
    img: `${eid_alfitr}`,
    des: "Share the joy of Eid al-Fitr with our special collection. Each chocolate is a sweet delight, perfect for celebrating the end of Ramadan with loved ones.",
    btn: "/eid-Al-Fitr",
    index: "6",
  },
  {
    heading: "Eid Al-Adha",
    img: `${eid_adha}`,
    des: "Celebrate Eid al-Adha with our exclusive collection. Each chocolate is a symbol of gratitude and joy, perfect for sharing during this special time.",
    // btn: "/eid-Al-Adha",
    index: "7",
  },
  {
    heading: "Hajj",
    img: `${hajj}`,
    des: "Commence your spiritual journey with our Hajj collection. Each chocolate is a reminder of faith and devotion, perfect for commemorating this sacred pilgrimage.",
    // btn: "/hajj",
    index: "8",
  },
  {
    heading: "Back to School",
    img: `${back_to_school}`,
    des: "Get ready to conquer the school year with our back-to-school collection. Each chocolate is a tasty treat, perfect for adding a bit of fun to your day.",
    index: "8",
    // btn: "/back-to-school",
  },
  {
    heading: "Teachers Day",
    img: `${teachers_day}`,
    des: "Say thank you to your favorite teacher with our Teacher's Day collection. Each chocolate is a sweet gesture, perfect for showing appreciation for all they do.",
    index: "9",
    // btn: "/teachers-Day",
  },
  {
    heading: "Halloween",
    img: `${halloween}`,
    des: "Dive into the spooky fun of Halloween with our chillingly delicious collection. Each chocolate is a hauntingly good treat, perfect for adding a touch of spookiness to your celebrations.",
    // btn: "/halloween",
    index: "10",
  },
  {
    heading: "UAE National Day",
    img: `${uae}`,
    des: "Celebrate UAE National Day with pride and joy with our special collection. Each chocolate is a symbol of unity and prosperity, perfect for honoring this momentous occasion.",
    index: "11",
    // btn: "/uae-national-day",
  },
  {
    heading: "Diwali",
    img: `${diwali}`,
    des: "Light up your Diwali celebrations with our festive collection. Each chocolate is a symbol of joy and prosperity, perfect for adding sweetness to your festivities.",
    // btn: "/diwali",
    index: "12",
  },
  {
    heading: "Christmas",
    img: `${christmas}`,
    des: "Indulge in a moment of pure joy with Rouge's chocolate delights. Each bite is a celebration, a spark of happiness that lingers. Share the joy of Rouge's collection and experience the wonder of chocolate that shines with celebration and generosity.",
    // btn: "/christmas",
    index: "13",
  },
  {
    heading: "Women Day",
    img: `${womensday}`,
    des: "Empower and celebrate the women in your life with our Women's Day collection. Each chocolate is a sweet reminder of strength and beauty, perfect for honoring the women who inspire us every day.",
    // btn: "/womens-day",
    index: "14",
  },
  {
    heading: "Father’s Day",
    img: `${Fathers_day}`,
    des: "Show Dad your appreciation with our Father's Day collection. Each chocolate is a token of gratitude and love, perfect for celebrating the amazing dads in our lives.",
    // btn: "/fathers-day",
    index: "15",
  },
  {
    heading: "Emirate Women’s Day",
    img: `${Emirate_Day}`,
    des: "Empower and celebrate Emirati women with our special collection. Each chocolate is a tribute to their strength and grace, perfect for honoring their invaluable contributions to society.",
    // btn: "/emirate-womens-day",
    index: "16",
  },
];

const Seasons = () => {

 const ButtonComponent = ({ btn }) => {
   if (!btn) {
     return (
       
         <span>COMING SOON</span>
       
     );
   } else {
     return (
       <Link to={btn}>
         
           <span>VIEW COLLECTION</span>
        
       </Link>
     );
   }
 };

  

  return (
    <div className="chocolate">
      <div
        className="pro_head"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
          width: "100%",
        }}
      >
        <h1>Seasons</h1>
      </div>

      <div className="max-width">
        {/* <div className="head_season">
          <h1>Seasons</h1>
        </div> */}
        <div className="home_nav">
          <Link to={"/"}>
            <span>
              <HomeIcon />
            </span>
          </Link>
          <span>|</span>
          <span>Seasons</span>
        </div>
      </div>
      <div className="max-width">
        <div className="max-width_wrap">
          {DataList.length > 0 &&
            DataList.map((item) => (
              <div className="product_info_section_one" key={item.index}>
                <div className="img_wrap">
                  <img src={item.img} />
                </div>
                <div className="text">
                  <h1>{item.heading}</h1>
                  <div className="strong">
                    <strong>{item.des}</strong>
                  </div>

                  <div className="btn_wrap">
                    <ButtonComponent btn={item.btn} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Seasons;
