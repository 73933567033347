import React, { useEffect, useState } from "react";
import "./Products.scss";
import List from "../../components/List/List";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import backImg from "../../img/ban.jpg";
import axios from "axios";

const Products = () => {
  const catId = parseInt(useParams().id);
    const [selectedProCat, setSelectedProCat] = useState([]);

  const [selectSubCategory, setSelectSubCategory] = useState([]);

  const [maxPrice, setMaxPrice] = useState(200);
  const [sort, setSort] = useState(null);
   const [proCat, setProCat] = useState([]);

  const { data, loading, error } = useFetch(
    `/sub-categories?[filters][categories][id][$eq]=${catId}`
  );
  // console.log(data);

  const getProCat = () => {
    axios
      .get(
        `https://rouge.backend-api.live/api/product-categories
`
      )
      .then((response) => {
        // Assuming response.data is an array
        setProCat(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  
  

  const handleChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectSubCategory((prevSubCats) => {
      if (isChecked) {
        return [...prevSubCats, value];
      } else {
        return prevSubCats.filter((item) => item !== value);
      }
    });
  };

  const handleChangeProductsCat = (e) => {
    const value = e.target.value;

    setSelectedProCat([value]);
  };
  
  useEffect(()=>{
    getProCat()

  },[])

  

  return (
    <div className="product_con">
      <div
        className="pro_head"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
          width: "100%",
        }}
      >
        <h1>All Products</h1>
      </div>
      <div className="max-width">
        <div className="products __wrap">
          <div className="left">
            <div className="filterItem">
              <h2>Seasons</h2>
              {data?.map((item, index) => (
                <div className="inputItem" key={item.id}>
                  <input
                    type="checkbox"
                    id={item.id}
                    value={item.id}
                    onChange={handleChange}
                  />
                  <label htmlFor={item.id}>{item.attributes.title} </label>
                </div>
              ))}
            </div>
            <div className="filterItem">
              <div className="heading">
                <h3>Categories</h3>
                <br />
              </div>
              <div className="inputItem">
                <select onChange={handleChangeProductsCat} defaultValue="">
                  <option value="" disabled>
                    Select Category
                  </option>

                  {proCat?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.attributes.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="filter-list">
              <h2>Seasons</h2>
              <div className="filterItem_mobile">
                <>
                  {data?.map((item) => (
                    <div className="inputItem" key={item.id}>
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.id}
                        onChange={handleChange}
                      />
                      <label htmlFor={item.id}>{item.attributes.title}</label>
                    </div>
                  ))}
                </>
                <div className="_filterItem">
                  <div className="heading">
                    <h4>Categories</h4>
                    <br />
                  </div>
                  <div className="inputItem">
                    <select onChange={handleChangeProductsCat} defaultValue="">
                      <option value="" disabled>
                        Select Category
                      </option>

                      {proCat?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.attributes.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="coming_soon">
              <h5>Coming Soon</h5>
              <br />
              <ul>
                <li>New Year</li>
                <li>Chinese New Year</li>
                <li>Valentine</li>
                <li>Mother Day</li>
                <li>Easter</li>
                <li>Eid El Fitr</li>
                <li>Adha & Hajj</li>
              </ul>
            </div> */}
          </div>

          <div className="right">
            {/* <div className="free_banner">
              <img src={require("../../img/fd.png")} />
            </div> */}
            <List
              proCat={selectedProCat}
              catId={catId}
              // maxPrice={maxPrice}
              sort={sort}
              subCats={selectSubCategory}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
