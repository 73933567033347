import "./FeaturedProducts.scss";
import Card from "../../components/Card/Card";
import useFetch from "../../hooks/useFetch";

const FeaturedProducts = ({ type }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const { data, loading, error } = useFetch(
    `/products?populate=*&[filters][type][$eq]=${type}`
  );



  return (
    <div className="max-width">
      <div className="featuredProducts">
        <div className="bottom" onClick={scrollToTop}>
          {error
            ? "Get ready for something extraordinary !"
            : loading
            ? "loading"
            : data?.map((item) => <Card item={item} key={item.id} />)}
        </div>
      </div>
    </div>
  );
};

export default FeaturedProducts;
