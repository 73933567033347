import React from "react";
import "./List.scss";
import Card from "../Card/Card";
import useFetch from "../../hooks/useFetch";

const List = ({ subCats, maxPrice, proCat, sort, catId }) => {
  const { data, loading, error } = useFetch(
    `/products?populate=*` +
      `${
        proCat.length > 0
          ? `&filters[product_categories][id][$eq]=${proCat[0]}`
          : ""
      }` +
      `${
        subCats.length > 0
          ? `&filters[sub_categories][id][$eq]=${subCats.join(",")}`
          : ""
      }`
  );

  // Reverse the data array to display the last added product first
  const reversedData = data ? data.slice().reverse() : [];

  return (
    <div className="list">
      {loading
        ? "loading"
        : reversedData.map((item) => <Card item={item} key={item.id} />)}
    </div>
  );
};


export default List;



