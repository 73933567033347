import React, { useEffect, useState } from "react";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import "./Home.scss";

import sample from "../../img/hero.mp4";
import one from "../../img/one.webm";
import two from "../../img/two.webm";
import three from "../../img/three.webm";
import backImg from "../../img/banner.jpg";
import { Link } from "react-router-dom";
import Newsletter from "../../components/Newsletter/Newsletter";
import Popupdv from "../../components/popup/Popup";
import SlideShow from "../../components/slideshow/SlideShow";
import Social from "../../components/Social/Social";
import Modal from "../../components/modal/Modal";

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="max-width">
      <div className="home">
        <SlideShow />
        {/* <div className="promo_banner">
          <div className="banner_text">
            <h1>
              A Blend of Passion <br /> and <em>Innovation</em> in Chocolate
            </h1>
          </div>
          
        </div> */}
        <div className="min_with">
          <div className="featured">
            <h1>OUR OFFERINGS</h1>
            <br />
            <div className="head_text">
              <strong>
                Tailored for every mood, occasion, event, <br /> and your daily
                moments of indulgence. Discover an array of abundant choices and{" "}
                <br /> savor the sensational experience to share.
              </strong>
            </div>
            {/* <div className="shppping">
            <Link to={"/products/1"}>
              <span>Shop Now</span>
            </Link>
          </div> */}
          </div>

          {/* <div className="featured">
          <hr />
          <div className="tranding">
            <h2>Our Trending Products</h2>
            <br />
            <FeaturedProducts type="featured" />
            <div className="shppping">
              <Link to={"/products/1"}>
                <span>Continue Shopping</span>
              </Link>
            </div>
          </div>
        </div> */}

          <div className="pro_cat">
           
              <div className="pro_cat_sec">
                <div className="pro_cat_item">
                  <div className="img">
                    <img src={require("../../img/rouge_cho.jpg")} />
                  </div>
                </div>
                <h2>Chocolates</h2>
              </div>
            
            <Link to={"/gift-boxes"} onClick={scrollToTop}>
              <div className="pro_cat_sec">
                <div className="pro_cat_item">
                  <div className="img">
                    <img src={require("../../img/rouge_boxes.jpg")} />
                  </div>
                </div>
                <h2>Boxes</h2>
              </div>
            </Link>
            <Link to={"/gift-arrangements"} onClick={scrollToTop}>
              <div className="pro_cat_sec">
                <div className="pro_cat_item">
                  <div className="img">
                    <img src={require("../../img/rouge_arg.jpeg")} />
                  </div>
                </div>
                <h2>Gift Arrangements</h2>
              </div>
            </Link>
            <Link to={"/Seasons"} onClick={scrollToTop}>
              <div className="pro_cat_sec">
                <div className="pro_cat_item">
                  <div className="img">
                    <img src={require("../../img/rouge_box.jpeg")} />
                  </div>
                </div>
                <h2>Seasons</h2>
              </div>
            </Link>
          </div>
        </div>

        <div className="colorfull">
          <div className="max-width">
            <div className="product_info_section_one">
              <div className="text">
                <strong>
                  <em>R</em>ouge's chocolate experience is simply sensational.
                  It's the dynamism of contemporary luxury that shifts and flows
                  with the seasons and trends. The celebration of each instance
                  through flavors, textures, colors, aromas, and rustling
                  expectation. The creation of memories. It's the Rouge you
                  love. A melting pot of passion and innovation in chocolate.
                </strong>
              </div>
              <div className="img_wrap">
                <img src={require("../../img/hero_img2.jpg")} />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="max-width">
            <div className="product_info_section_one">
              <div className="img_wrap">
                <img src={require("../../img/hero_img.jpg")} />
              </div>
              <div className="text">
                <div className="strong">
                  <strong>
                    <em>R</em>ouge offers a contemporary luxury chocolate
                    experience, blending passion with innovation. Our creations
                    embody a mix of heritage, savoir-faire, quality, and
                    craftsmanship. Each piece is a showcase of sensory enjoyment
                    and emotions, fostering relationships, stories,
                    conversations, and memories shared with generosity and
                    wonder.
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
    </div>
  );
};

export default Home;
