import React from 'react'
import "./category.scss";

const Category = () => {
  return (
    <div className="category">
      <div className="max-width">
        <div className="product_info_section_one">
          <div className="img_wrap">
            <img src={require("../../img/hero_img.jpg")} />
          </div>
          <div className="text">
            <div className="strong">
              <strong>
                <em>R</em>ouge offers a contemporary luxury chocolate
                experience, blending passion with innovation. Our creations
                embody a mix of heritage, savoir-faire, quality, and
                craftsmanship. Each piece is a showcase of sensory enjoyment and
                emotions, fostering relationships, stories, conversations, and
                memories shared with generosity and wonder.
              </strong>
            </div>
            <div className='btn_wrap'>
              <span>DISCOVER OUR PRODUCTS</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category
