import React from "react";
import "./promotions.scss";
import backimg from "../../img/back.jpeg";

const Promotion = () => {

   const handleSubmit = (e) => {
     e.preventDefault();
     window.open("https://winatriptodubai.com/Duty-Free/");
   };

  return (
    <div className="max-width">
      <div
        className="promotion"
        style={{
          backgroundImage: `url(${backimg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
          height: "80vh",
        }}
      >
        <div className="prom_con">
          <div className="pro-content">
            <div className="pro_fom_con">
              <div></div>
              <form onSubmit={handleSubmit}>
                <h1>
                  Enter The Code and Win
                </h1>
                <input
                  
                  minLength="9"
                  required
                  type="text"
                  placeholder="Enter your code"
                />
                <button type="submit">CONTINUE</button>
              </form>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotion;
