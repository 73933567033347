import React from 'react'
import { Link } from 'react-router-dom';
import "./category.scss";
import HomeIcon from "@mui/icons-material/Home";
import backImg from "../../img/season_img/hero.jpg";
import FeaturedProducts from '../FeaturedProducts/FeaturedProducts';

const NewBorn = () => {
  return (
    <div className="chocolate">
      <div
        className="pro_head"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
          width: "100%",
        }}
      >
        <h1>New Born</h1>
      </div>
      <div className="max-width">
        <div className="home_nav">
          <Link to={"/"}>
            <span>
              <HomeIcon />
            </span>
          </Link>
          <span>|</span>
          <Link to={"/gift-arrangements"}>
            <span>Gift Arrangements</span>
          </Link>
          <span>|</span>
          <span>New Born</span>
        </div>
        <div className="product_list">
          <FeaturedProducts type="normal" />
        </div>
      </div>
    </div>
  );
}

export default NewBorn
