import React, { useEffect, useState } from "react";
import "./App.scss";
import axios from "axios";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./page/Home/Home";
import Products from "./page/Products/Products";
import Product from "./page/Product/Product";

import Contact from "./page/Contact/Contact";
import About from "./page/About/About";
import Conditions from "./page/t&c/Conditions";
import PrivacyPolicy from "./page/Policy/PrivacyPolicy";
import Succsess from "./components/payment/Succsess";
import Failed from "./components/payment/Failed";
import Navbar from "./components/Navbar/Navbar";
import Whatapp from "./components/whatapp/Whatapp";
import Footer from "./components/Footer/Footer";
import Error from "./page/Error/Error";
import Promotion from "./page/promotion/Promotion";
import Modal from "./components/modal/Modal";
import Pay_Icon from "./components/PaymentIcon/Pay_Icon";
import Category from "./page/category/Category";
import Chocolates from "./page/category/Chocolates";
import GiftBoxes from "./page/category/GiftBoxes";
import GiftArrangements from "./page/category/ GiftArrangements";
import Seasons from "./page/category/Seasons";
import NewBorn from "./page/category/NewBorn";
import NewYear from "./page/category/NewYear";
import ChineseNewYear from "./page/category/ChineseNewYear";
import Valentine from "./page/category/Valentine";
import Ramadan from "./page/category/Ramadan";
import MothersDay from "./page/category/MothersDay";
import Easter from "./page/category/Easter";
import EidAlFitr from "./page/category/EidAlFitr";
import EidAlAdha from "./page/category/EidAlAdha";
import Hajj from "./page/category/Hajj";
import BackToSchool from "./page/category/BackToSchool";
import TeachersDay from "./page/category/TeachersDay";
import Halloween from "./page/category/Halloween";
import UAENationalDay from "./page/category/UAENationalDay";
import Diwali from "./page/category/Diwali";
import Christmas from "./page/category/Christmas";
import WomenDay from "./page/category/WomenDay";
import FathersDay from "./page/category/FathersDay";
import EmirateWomensDay from "./page/category/EmirateWomensDay";
import Classics from "./page/category/Classics";
import Weddings from "./page/category/Weddings";
import Graduation from "./page/category/Graduation";
import PromoForm from "./page/form/PromoForm";
import SrcImage from "./page/srcimage/SrcImage";

// pages for the router

// axios.defaults.baseURL = "http://localhost:4544/api/v1";
axios.defaults.baseURL = "https://notification.rouge.ae/api/v1";
axios.defaults.withCredentials = true;

const Layout = () => {
  return (
    <div className="app" style={{}}>
      <Navbar />
      <Whatapp />

      <Outlet />
      <Footer />
      <Pay_Icon />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/products/:id",
        element: <Products />,
      },

      {
        path: "/product/:id",
        element: <Product />,
      },

      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/promotion",
        element: <Promotion />,
      },
      {
        path: "/classics",
        element: <Classics />,
      },
      // ! sub pages
      {
        path: "/new-year",
        element: <NewYear />,
      },
      {
        path: "/chinese-new-year",
        element: <ChineseNewYear />,
      },
      {
        path: "/valentine",
        element: <Valentine />,
      },
      {
        path: "/ramadan",
        element: <Ramadan />,
      },
      {
        path: "/mothers-Day",
        element: <MothersDay />,
      },
      {
        path: "/easter",
        element: <Easter />,
      },
      {
        path: "/eid-Al-Fitr",
        element: <EidAlFitr />,
      },
      {
        path: "/eid-Al-Adha",
        element: <EidAlAdha />,
      },
      {
        path: "/hajj",
        element: <Hajj />,
      },
      {
        path: "/back-to-school",
        element: <BackToSchool />,
      },
      {
        path: "/teachers-Day",
        element: <TeachersDay />,
      },
      {
        path: "/halloween",
        element: <Halloween />,
      },
      {
        path: "/uae-national-day",
        element: <UAENationalDay />,
      },
      {
        path: "/diwali",
        element: <Diwali />,
      },
      {
        path: "/christmas",
        element: <Christmas />,
      },
      {
        path: "/popup",
        element: <PromoForm />,
      },
      {
        path: "/womens-day",
        element: <WomenDay />,
      },
      {
        path: "/fathers-day",
        element: <FathersDay />,
      },
      {
        path: "/emirate-womens-day",
        element: <EmirateWomensDay />,
      },
      //!------------

      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/image",
        element: <SrcImage />,
      },
      {
        path: "/terms-&-conditions",
        element: <Conditions />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },

      {
        path: "/succsess",
        element: <Succsess />,
      },
      {
        path: "/chocolates",
        element: <Chocolates />,
      },
      {
        path: "/gift-arrangements",
        element: <GiftArrangements />,
      },
      {
        path: "/Seasons",
        element: <Seasons />,
      },
      {
        path: "/gift-boxes",
        element: <GiftBoxes />,
      },
      {
        path: "/new-born",
        element: <NewBorn />,
      },
      {
        path: "/graduation",
        element: <Graduation />,
      },
      {
        path: "/weddings",
        element: <Weddings />,
      },
      {
        path: "/failed",
        element: <Failed />,
      },
      {
        path: "/chocolates",
        element: <Category />,
      },
      {
        path: "*",
        element: <Error />,
      },
      {
        path: "/mail",
        element: <Error />,
      },
    ],
  },
]);

//main component

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsModalOpen(true);
    }, 2000);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <RouterProvider router={router} />{" "}
      {/* <Modal isOpen={isModalOpen} closeModal={closeModal}></Modal>*/}
    </div>
  );
};

export default App;
