import React from "react";
import "./Copyright.scss";

const Copyright = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="copyright">
      <div className="copyright_info">
        <span>
          Copyright © {currentYear} Notions Group. All Rights Reserved.
        </span>
      </div>
    </div>
  );
};

export default Copyright;
