import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import HomeIcon from "@mui/icons-material/Home";
import Cart from "../Cart/Cart";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import "./mobile.css";
import { CiShop } from "react-icons/ci";
import { AiFillShop } from "react-icons/ai";
import { FaShop } from "react-icons/fa6";
import { BsShop } from "react-icons/bs";
import { lightBlue } from "@mui/material/colors";

const Navbar = () => {
  const [open, setOpen] = useState(false);
    const [close, setClose] = useState(true);
  const products = useSelector((state) => state.cart.products);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="nav_con">
      <div className="my-navbar">
        <nav class="navbar navbar-expand-lg ">
          <div class="container-fluid">
            <div className="logo-top">
              <div>
                <button
                  class="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="toggler-icon top-bar"></span>
                  <span class="toggler-icon middle-bar"></span>
                  <span class="toggler-icon bottom-bar"></span>
                </button>
              </div>

              <div className="center">
                <Link onClick={scrollToTop} className="link" to="/">
                  <img src={require("../../img/logo.png")} alt="" />
                </Link>
              </div>
              <div className="item">
                <div className="icons">
                  <div className="cartIcon" onClick={() => setOpen(!open)}>
                    <ShoppingCartIcon color="red" />
                    <span>{products.length}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <Link onClick={scrollToTop} className="link" to="/chocolates">
                    Chocolates
                  </Link>
                </li>
                <li class="nav-item">
                  <Link onClick={scrollToTop} className="link" to="/gift-boxes">
                    Boxes
                  </Link>
                </li>

                <li class="nav-item">
                  <Link
                    onClick={scrollToTop}
                    className="link"
                    to="/gift-arrangements"
                  >
                    Gift Arrangements
                  </Link>
                </li>
                <li class="nav-item">
                  <Link onClick={scrollToTop} className="link" to="/Seasons">
                    Seasons
                  </Link>
                </li>
                <li class="nav-item">
                  <Link onClick={scrollToTop} className="link" to="/products/1">
                    Online Shop
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* ------------desktop wall paper----------------- */}

        <div className="wrapper">
          <div className="center">
            <Link onClick={scrollToTop} className="link" to="/">
              <img src={require("../../img/logo.png")} alt="" />
            </Link>
          </div>
          <div></div>

          <div className="right">
            <div className="item">
              <Link
                onClick={scrollToTop}
                style={{ color: "red" }}
                className="link"
                to="/products/1"
              >
                <BsShop fontSize={20} color="red" /> &nbsp; Shop Online
              </Link>
            </div>

            {/* <div className="item">
            <Link onClick={scrollToTop} className="link" to="/about">
              About
            </Link>
          </div>

          <div className="item">
            <Link onClick={scrollToTop} className="link" to="/contact">
              Contact
            </Link>
          </div> */}
            <div className="item">
              <div className="icons">
                <div className="cartIcon" onClick={() => setOpen(!open)}>
                  <ShoppingCartIcon color="red" />
                  <span>{products.length}</span>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>

        {open && <Cart />}
      </div>
    </div>
  );
};

export default Navbar;
