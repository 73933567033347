import React from 'react'
import "./srcImage.scss";

const SrcImage = () => {
  return (
    <div className='img_wrp'>
      <img src={require("../../img/mailImg.png")} alt="" />
    </div>
  );
}

export default SrcImage
