import React from "react";
import "./Style.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";

const Succsess = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="succsess">
      <div className="card_wrap">
        <div className="logo">
         
        </div>
        <h1>
          Thanks you <br /> for your purchese
        </h1>
        <div className="btn">
          <Link to="/" onClick={scrollToTop}>
            <button>Home</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Succsess;
