import React from 'react'
import backImg from "../../img/hero.jpg";
import FeaturedProducts from '../FeaturedProducts/FeaturedProducts';
import "./category.scss";
import { Link } from 'react-router-dom';
import HomeIcon from "@mui/icons-material/Home";


import Classic from "../../img/season_img/Classic.jpg";
import newborn from "../../img/season_img/newborn.jpeg";
import wedding from "../../img/season_img/wedding.jpg";
import Grad from "../../img/season_img/Grad.jpg";


const DataList = [
  {
    heading: "Classics",
    img: `${Classic}`,
    des: "Embrace the classic elegance of our collection. Each chocolate is a testament to timeless taste and quality, perfect for savoring moments of refined indulgence.",
    btn: "/classics",
    index: "1",
  },
  {
    heading: "New Born",
    img: `${newborn}`,
    des: "Let us bring your dream theme to life for your newborn baby boy or girl. Create precious memories with each costume, celebrating the start of a beautiful journey together.",
    // btn: "/new-born",
    index: "2",
  },
  {
    heading: "Weddings",
    img: `${wedding}`,
    des: "Toast to love and happiness with our wedding collection. Each chocolate is a sweet token of your special day, perfect for celebrating the start of your new journey together.",
    // btn: "/weddings",
    index: "3",
  },
  {
    heading: "Graduation",
    img: `${Grad}`,
    des: "Congratulations, graduate! Celebrate your achievement with our special collection. Each chocolate is a sweet reward for your hard work and dedication, perfect for marking this milestone moment.",
    // btn: "/graduation",
    index: "4",
  },
];


const  GiftArrangements = () => {


  const ButtonComponent = ({ btn }) => {
    if (!btn) {
      return <span>COMING SOON</span>;
    } else {
      return (
        <Link to={btn}>
          <span>VIEW COLLECTION</span>
        </Link>
      );
    }
  };




  return (
    <div className="chocolate">
      <div
        className="pro_head"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
          width: "100%",
        }}
      >
        <h1>Gift Arrangements</h1>
      </div>

      <div className="max-width">
        {/* <div className="head_season">
          <h1>Seasons</h1>
        </div> */}
        <div className="home_nav">
          <Link to={"/"}>
            <span>
              <HomeIcon />
            </span>
          </Link>
          <span>|</span>
          <span>Gift Arrangements</span>
        </div>
      </div>
      <div className="max-width">
        <div className="max-width_wrap">
          {DataList.length > 0 &&
            DataList.map((item) => (
              <div className="product_info_section_one" key={item.index}>
                <div className="img_wrap">
                  <img src={item.img} />
                </div>
                <div className="text">
                  <h1>{item.heading}</h1>
                  <div className="strong">
                    <strong>{item.des}</strong>
                  </div>

                  <div className="btn_wrap">
                    <ButtonComponent btn={item.btn} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default  GiftArrangements
